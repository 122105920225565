import React from "react";
import { Paragraph, TextContainer, Ul } from "@siteimprove/fancylib";

import {
	Knobs,
	Example,
	DocPageMeta,
	ContentSection,
	HeaderSection,
	InlineMessage,
	ImportExample,
	Header,
	Code,
} from "../../../../../src/docs";
import { LabWarning } from "../../../../../src/docs/docs-lab-warning";
import { Breadcrumbs } from "./breadcrumbs";

export const Meta: DocPageMeta = {
	category: "Navigation",
	title: "Breadcrumbs",
	notepad: "https://hackmd.io/LdaLY32nTBiCtrFfm3xrFw",
};

export default (): JSX.Element => (
	<>
		<HeaderSection
			title="Breadcrumbs"
			subTitle="Show users their path and allow quick backtracking to higher-level pages."
		/>
		<ContentSection>
			<TextContainer article>
				<LabWarning />
				<ImportExample lab component={Breadcrumbs} />
				<Header.H2>Examples</Header.H2>
				<Paragraph>
					Breadcrumbs are a secondary navigation aid, showing users their current location within an
					application and enabling quick navigation back to higher-level pages.
				</Paragraph>
				<Header.H3>Default</Header.H3>
				<Paragraph>
					The standard breadcrumb displays a clickable path of the user's current location. Each
					segment represents a level in the hierarchy, separated by the visual delimiter "/". The
					final segment (current page) is rendered as text, and therefore the last item in the{" "}
					<Code>items</Code> array should not have a <Code>url</Code> or <Code>onClick</Code> prop
					or the value of those should be <Code>null</Code>.
				</Paragraph>
				<Paragraph>
					<b>Best practices</b>:
				</Paragraph>
				<Ul
					items={[
						"Use for applications with multi-step processes where users need to track progress.",
						"Start with the highest-level parent page and move deeper into the information architecture as the breadcrumb trail progresses.",
						"Limit breadcrumbs to a maximum of 6 items for optimal usability. (See guideline below).",
					]}
				/>
				<Paragraph>
					<b>Use cases</b>:
				</Paragraph>
				<Ul
					items={[
						"Use for applications with multi-step processes where users need to track progress.",
					]}
				/>
				<Example fn={BasicUsage} />
				<Header.H3>Non-navigable breadcrumb</Header.H3>
				<Paragraph>
					This type visually represents the path but without clickable links or <Code>onClick</Code>{" "}
					handlers. It's used to show the user's location. In the example below, the{" "}
					<Code>url</Code> props have the value <Code>null</Code>, but the props can also be
					omitted.
				</Paragraph>
				<Paragraph>
					<b>Use cases</b>:
				</Paragraph>
				<Ul
					items={[
						"Use for read-only views (e.g., search results) where navigation isn't necessary.",
						"Use when the hierarchy is simple and easily understood without clicking.",
					]}
				/>
				<Example fn={NonNavigableExample} />
				<Header.H3>Breadcrumb with custom "onclick" handlers</Header.H3>
				<Paragraph>
					This variation allows custom actions (e.g., filtering, triggering functions) when a
					breadcrumb segment is clicked.
				</Paragraph>
				<Paragraph>
					<b>Best practices</b>:
				</Paragraph>
				<Ul items={["Page content-specific actions tied to the breadcrumb's context"]} />
				<Example fn={WithOnClickEventExample} />
				<Header.H2>Properties</Header.H2>
				<Knobs
					component={Breadcrumbs}
					initialProps={{
						items: [
							{ title: "Siteimprove", url: "https://siteimprove.com/" },
							{ title: "Github", url: "https://github.com/" },
							{ title: "Not Navigable" },
						],
						"aria-label": "Breadcrumbs",
					}}
				/>
				<Header.H2>Guidelines</Header.H2>
				<Header.H3>Best practices</Header.H3>
				<InlineMessage variant="best-practices">
					<Header.H4>General</Header.H4>
					<Paragraph>
						Use <Code>Breadcrumbs</Code> when
					</Paragraph>
					<Ul
						items={[
							"There are multiple navigation levels and a clear hierarchy.",
							"Users need to understand their location.",
							"Users may want to quickly return to higher-level pages.",
						]}
					/>
					<Header.H4>Placement</Header.H4>
					<Paragraph>
						<Code>Breadcrumbs</Code> are typically used in the following places:
					</Paragraph>
					<Ul items={["Above the page header (most common).", "Above the main content."]} />
					<Header.H4>Style</Header.H4>
					<Ul
						items={[
							<>
								<b>Siteimprove Design System</b>: Adhere to Siteimprove's guidelines for color,
								typography, and spacing. If you are not using a component from Fancy, match the
								styling of your <Code>Breadcrumbs</Code> to existing components for visual
								consistency.
							</>,
							<>
								<b>Delimiter</b>: Use a clear and visually distinct delimiter between segments e.g.
								("/").
							</>,
							<>
								<b>Typography</b>: Use a legible font size (e.g <Code>$font-size--medium</Code>) and
								style (e.g <Code>$font-family--default</Code> and <Code>$font-weight--normal</Code>)
								that contrasts with the background.
							</>,
							<>
								<b>Color</b>: Use a color (by default, is using <Code>$color--blue</Code>) that
								contrasts with the background and does not clash with other elements on the page.
							</>,
							<>
								<b>Breadcrumb maximum items</b>: Limit <Code>Breadcrumbs</Code> to a maximum of 6
								items for optimal usability. If there is not enough space to fully display the
								breadcrumbs, automatically collapse them, showing the first and last items with an
								ellipsis ("...") in between. The ellipsis should be clickable to reveal all items.
								Examples:
								<Ul
									items={[
										<>
											<b>Original</b>: Item 1 / Item 2 / Item 3 / Item 4 / Item 5 / Item 6
										</>,
										<>
											<b>Collapsed</b>: Item 1 / ... / Item 7
										</>,
									]}
								/>
							</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Do not use when</Header.H3>
				<InlineMessage variant="do-not-use-when">
					<Ul
						items={[
							"Single-level pages.",
							"When the user's location is always obvious (e.g., Side Navigation’s item).",
							"When breadcrumbs would take up valuable space without providing a clear benefit.",
						]}
					/>
				</InlineMessage>
				<Header.H3>Accessibility</Header.H3>
				<InlineMessage variant="accessibility">
					<Header.H4>For designers</Header.H4>
					<Ul items={["Ensure sufficient color contrast between the text and background"]} />
					<Header.H4>For developers</Header.H4>
					<Paragraph>
						This component comes with built-in accessibility, no extra work required.
					</Paragraph>
				</InlineMessage>
				<Paragraph>
					Explore detailed guidelines for this component:{" "}
					<a href="https://siteimprove-wgs.atlassian.net/wiki/x/4wQNeQ">
						Accessibility Specifications
					</a>
				</Paragraph>
				<Header.H3>Writing</Header.H3>
				<InlineMessage variant="writing">
					<Ul
						items={[
							"Each segment should accurately reflect the corresponding page's title or content.",
							"Keep labels concise and clear.",
						]}
					/>
				</InlineMessage>
			</TextContainer>
		</ContentSection>
	</>
);

const BasicUsage = () => {
	const items = [
		{ title: "Siteimprove", url: "https://siteimprove.com/" },
		{ title: "Github", url: "https://github.com/" },
		{ title: "Wikipedia" },
	];

	return <Breadcrumbs items={items} aria-label="Breadcrumbs" />;
};

const NonNavigableExample = () => {
	const items = [
		{ title: "Siteimprove", url: null },
		{ title: "Github", url: null },
		{ title: "Not Navigable", url: null },
	];

	return <Breadcrumbs items={items} aria-label="Breadcrumbs - non-navigable current page" />;
};

const WithOnClickEventExample = () => {
	const items = [
		{ title: "Products", url: "#", onClick: () => alert("Products") },
		{ title: "Documentation", url: "#", onClick: () => alert("Documentation") },
		{ title: "Contact us" },
	];

	return <Breadcrumbs items={items} aria-label="Breadcrumbs - custom onclick event" />;
};
